.overview-card {
  padding: 10px;
  background-color: whitesmoke;
  border-left: 8px solid;
  border-radius: 8px;
  min-width: 15%;
  margin-right: 24px;
  &__loader {
    width: 100%;
  }
  &_side-border-blue {
    border-left-color: #0a4091;
  }

  &_side-border-green {
    border-left-color: green;
  }

  &_side-border-red {
    border-left-color: orangered;
  }
}
