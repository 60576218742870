@import "../../styles/abstract/colors";
.search-box {
  input {
    width: 100%;
    border-radius: 3px 0 0 3px;
  }

  &__search-icon-container {
    border: 1px solid lightgray;
    padding: 6px;
  }

  &__clear-icon-container {
    border: 1px solid lightgray;
    padding: 6px;
    border-radius: 0 3px 3px 0;
    background-color: $lightgray;
  }
}
