@import "../../styles/abstract/colors";

.delete-batch {
  background-color: $lightgray !important;
  padding: 8px;
  border-radius: 3px;
  height: 100%;

  &__inner-section {
    background-color: $white;
    height: 100%;
    border-radius: 3px;
    padding: 16px;
  }

  &__warning {
    border: 1px solid lightgray;
    padding: 8px;
    font-size: 14px;
  }

  &__action-section {
    width: 50%;
  }
}
