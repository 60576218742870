@import "../../styles/abstract/colors";

.navbar {
  height: 80px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(23, 2, 31, 1) 0%,
    #084298 100%
  );
  color: $white;
  padding-left: 16px;
  padding-right: 16px;

  &__left-section {
    // ADD TO MIXINS
    display: flex;
    align-items: center;
  }

  &__brand {
    display: flex;
    align-items: center;
    // margin-left: 32px;

    svg {
      margin-right: 4px;
    }
  }

  &__center-section {
    display: flex;
    align-items: center;

    span {
      font-size: 24px;
    }
  }

  &__right-section {
    display: flex;
    align-items: center;

    &__setting-icon-container {
      display: flex;
      align-items: center;
      border: 1px solid $white;
      padding: 4px 6px;
      border-radius: 2px;
      cursor: pointer;
    }

    &__report-icon-container {
      display: flex;
      align-items: center;
      margin-left: 24px;
      border: 1px solid $white;
      padding: 4px 6px;
      border-radius: 2px;
      cursor: pointer;
    }

    &__bell-icon-container {
      display: flex;
      align-items: center;
      margin-left: 24px;
      border: 1px solid $white;
      padding: 4px 6px;
      border-radius: 2px;
      cursor: pointer;
      position: relative;
    }

    &__user-icon-container {
      display: flex;
      align-items: center;
      margin-left: 24px;
      border: 1px solid $white;
      padding: 4px 6px;
      border-radius: 2px;
      cursor: pointer;
    }
  }

  &__notification-count {
    position: absolute;
    top: -9px;
    right: -8px;
    opacity: 1;
    padding: 2px;
    background: $blue;
    line-height: initial;
  }
}
