@import "../../styles/abstract/colors";

.item {
  width: 60vw;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid gray;
    background-color: #4a5456;
    border-radius: 3px;
    padding: 4px 8px;
    color: $white;
    font-size: 14px;
  }

  &__section {
    display: flex;
    align-items: center;
    padding: 8px 0 16px 0;
    overflow-x: scroll;
    margin-bottom: 8px;
  }
}
