@import "../../styles/abstract/colors";

.confirmed-cooked {
  background-color: $lightgray !important;
  padding: 8px;
  border-radius: 3px;
  height: 100%;

  &__inner-section {
    background-color: $white;
    height: 100%;
    padding: 16px;
    border-radius: 3px;
  }

  &__time-section {
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 4px;

    header {
      font-size: 12px;
    }

    .time-input {
      width: 40px;
      font-size: 24px;
      text-align: center;
    }
  }

  &__divider {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 24px;
  }

  &__confirm-btn {
    width: 88px;
    background: $btngreen !important;
  }

  &__cancel-btn {
    width: 88px;
    background: $btnred !important;
  }

  &__error-container {
    font-size: 14px;
    color: red !important;
    margin-top: 24px;
  }
}
