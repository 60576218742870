@import "../../styles/abstract/colors";

.summary-report {
  &__left-section-header {
    height: 40px;
    background-color: $lightgray;
    border-radius: 3px;
    padding-left: 8px;
    font-weight: bold;
  }

  &__left-section-items {
    span {
      padding: 16px 8px;
    }
  }
}
