.manager-current-sales {
  &__date-picker {
    width: 160px !important;
    cursor: pointer;
    display: flex;
  }

  &__search {
    margin-right: 18px;
    width: 25%;
  }

  &__table-container {
    width: 90vw !important;
  }

  .react-datepicker-wrapper {
    width: 120px;
  }

  &__date-picker-arrows {
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
  }
}
