.mrd-items {
  &__container {
    justify-content: center;
    &__header {
      width: 90vw;
    }
  }
  &__table-container {
    width: 90vw !important;
    &__align_center {
      text-align: center;
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }

  &__mdr_print_btn {
    font-size: 12px;
  }
}
