.history-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    flex-basis: 100%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__close-icon {
    cursor: pointer;
  }
}
