.dashboard {
  &__main {
    padding: 16px;

    &__time-section {
      display: flex;
      justify-content: space-between;
    }
  }
}
