@import "../../styles/abstract/colors";

.batch-tile-with-image {
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: $tilegreen;
  margin-right: 16px;
  min-width: 188px;
  height: 92px;
  cursor: pointer;

  &__count-container {
    display: flex;
    flex-direction: column;

    &--title {
      font-size: 12px;
    }
  }

  &__img {
    height: 100px;
    width: 94px;
    margin-right: 4px;
    margin-top: -1%;
  }
}
