@import "../../styles//abstract/colors";

.items-column {
  height: 78vh;

  &__collapse-btn {
    height: 78vh;
    background: $blue;
    margin-left: 16px;
    margin-right: 8px;
    border-radius: 3px;
    padding: 8px;
    background-color: #a4a9aa;
    cursor: pointer;
  }

  &--hide {
    display: none;
  }
}
