.store-summary {
  &__date-picker {
    width: 240px !important;
    margin-left: 5vw;
    cursor: pointer;
  }

  &__table-container {
    width: 90vw !important;
  }

  .react-datepicker-wrapper {
    width: 120px;
  }

  &__date-picker-arrows {
    border: 1px solid lightgray;
    border-radius: 3px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    cursor: pointer;
  }
}
