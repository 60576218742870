@import "../../styles/abstract/colors";

.legends {
  font-size: 14px;
  display: flex;

  &__legend-block {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__indicator {
    border: 1px solid gray;
    padding: 8px;
    margin-right: 8px;
    height: 16px;

    &--blue {
      background-color: $tileblue;
    }

    &--orange {
      background-color: $tileorange;
    }

    &--red {
      // background-color: $tilelightred;
      -webkit-animation: BLINK 1s infinite; /* Safari 4+ */
      -moz-animation: BLINK 1s infinite; /* Fx 5+ */
      -o-animation: BLINK 1s infinite; /* Opera 12+ */
      animation: BLINK 1s infinite; /* IE 10+, Fx 29+ */
    }

    &--gray {
      background-color: lightgray;
    }

    @keyframes BLINK {
      0%,
      49% {
        background-color: #ed2619;
      }
      50%,
      100% {
        background-color: #db0109;
      }
    }

    &--darkred {
      background-color: $tilered;
      color: white;
    }
  }
}
