@import "../../styles/abstract/colors";

.correct-qty {
  background-color: $lightgray !important;
  padding: 8px;
  border-radius: 3px;
  height: 100%;

  &__inner-section {
    background-color: $white;
    height: 100%;
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &__input {
    width: 88px;
    font-size: 48px;
    margin-left: 20px;
    text-align: center;
    margin-top: 24px;
  }

  &__right-section {
    border-left: 1px solid lightgray;

    &--first-child {
      padding-left: 8px;
    }
  }
}
