.percent-toggle {
  &__percent-toggle {
    span {
      border: 1px solid lightgray;
      padding: 4px 8px;
      cursor: pointer;
    }
  }

  &__percent-toggle--active {
    background-color: gray;
    color: #fff;
  }
}
