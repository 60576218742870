@import "../../styles/abstract/colors";

.update-batch-modal {
  &__header {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(23, 2, 31, 1) 0%,
      rgba(204, 11, 11, 0.9150035014005602) 100%
    );
    color: $white;
    padding: 16px;
  }

  &__section {
    .col-md-4 {
      padding: 0;
    }

    .col-md-8 {
      padding: 8px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    span {
      padding: 12px;
      background-color: $lightgray;
      border-bottom: 1px solid lightgray;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &__selected-menu {
    background-color: lightgray !important;
  }

  &__disabled {
    cursor: not-allowed !important;
    color: lightgray;
  }
}
