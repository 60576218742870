@import "../../styles/abstract/colors";

.batch-tile {
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-right: 16px;
  height: 92px;
  min-width: 120px;

  header {
    font-size: 12px;
    padding: 4px;
  }

  &--blue {
    background-color: $tileblue;
  }

  &--orange {
    background-color: $tileorange;
  }

  &--red {
    // background-color: $tilelightred;
    -webkit-animation: BLINK 1s infinite; /* Safari 4+ */
    -moz-animation: BLINK 1s infinite; /* Fx 5+ */
    -o-animation: BLINK 1s infinite; /* Opera 12+ */
    animation: BLINK 1s infinite; /* IE 10+, Fx 29+ */
  }

  @keyframes BLINK {
    0%,
    49% {
      background-color: #290d0a0c;
      color: rgb(14, 13, 13);
    }
    50%,
    100% {
      background-color: #0c0505;
      color: white;
    }
  }

  &--darkred {
    background-color: $tilered;
    color: white;
  }

  footer {
    font-size: 12px;
    padding: 0 4px;
  }

  &--disabled {
    cursor: not-allowed !important;
    background-color: lightgray;
  }
}
