.tile-count {
  padding: 8px;
  font-size: 20px;

  &--large {
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
  }
}
