.number-blocks {
  &__block {
    border: 2px solid lightgray;
    border-radius: 5px;
    height: 60px;
    width: 68px;
    margin-top: 8px;
    font-size: 2em;
    font-weight: 200;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}
