@import "../../styles/abstract/colors";

.button {
  width: 88px;

  &--green {
    background: $btngreen !important;
  }

  &--red {
    background: $btnred !important;
    color: $white !important;
  }
}
