@import "../../styles/abstract/colors";

.add-batch-modal {
  &__header {
    background: rgb(2, 0, 36);
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(23, 2, 31, 1) 0%,
      rgba(204, 11, 11, 0.9150035014005602) 100%
    );
    color: $white;
    padding: 16px;
  }

  &__content {
    padding: 16px 8px;
  }

  &__sub-header {
    font-size: 14px;
  }

  &__input {
    height: 80px;
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 0.5em;
  }
}
